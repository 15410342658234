import React, { useState } from 'react';
import Screen from '../../../../../components/screen';
import { useModuleState } from '../../../state';
import * as R from 'ramda';

import Grid from '../../../../../components/grid/Grid';
import BookersInfo from '../../../../../components/BookersInfo/BookersInfo';
import Roles from '../../../../../components/Roles/Roles';
import { getScreen } from '../../../../../selectors/portfolio-viewer';
// import Icon from "../../../../../components/icon-2";
// import classNames from "classnames";
import RequestButton from '../../request-button';
import { useMatomo } from '@datapunt/matomo-tracker-react';
import ZipModal from '../view-list/zip-modal';

export default () => {
  const {
    state,
    showModal,
    // ignored linter for now
    setOptionActorStatus,
    data: { sedcards, slideRoom /* , similarFacesById = {} */ },
    // pushStack,
  } = useModuleState();
  const screen = getScreen(state);
  const { trackEvent } = useMatomo();

  const [zipModal, setZipModal] = useState<boolean>();

  const id = screen.options ? screen.options.id : '';

  // const similarFaces = similarFacesById[id] || [];

  const sedcard = sedcards[id];

  if (!sedcard) {
    return null;
  }

  const pdfLinks = sedcard.Sedcards.filter((s) => s.Download).map(
    (s) => s.Download
  );

  // @ts-ignore
  const regexp = /(?=https).*(?!.)/g;
  let answerImages: string[] = [];
  if (sedcard.Antworten && sedcard.Antworten.length > 0) {
    answerImages = [...sedcard.Antworten.matchAll(regexp)];
  }

  return (
    <Screen>
      <div className="w-full" id="singleViewTop"></div>
      <ZipModal
        visibleActors={[sedcard]}
        slideRoom={slideRoom}
        showModal={zipModal}
        screen={'full'}
      ></ZipModal>
      <header className="listHeader py-2  px-4  flex  justify-between items-center sticky">
        <RequestButton id={id} />
        {/*<button
          className={classNames(
            "ml-2 flex justify-center items-center p-2 focus:outline-none",
            sedcard["BuchenStatus"] === "Ja" ? "bg-green" : "bg-brand-300",
            sedcard["Favoriten-Status"] || sedcard["BuchenStatus"] === "Ja"
              ? "cursor-default"
              : "interactive"
          )}
          disabled={sedcard["BuchenStatus"] === "Ja"}
          onClick={() => {

            trackEvent({ category: 'diaraum-darsteller', action: ["Favoriten-Status"] ? "UNFAVORITE" : "FAVORITE"})
            setOptionActorStatus(
              sedcard["Favoriten-Status"] ? "UNFAVORITE" : "FAVORITE",
              id
            )}
          }
        >
          <Icon
            name={
              sedcard["BuchenStatus"] === "Ja"
                ? "checksquare"
                : sedcard["Favoriten-Status"]
                ? "star-filled"
                : "star-empty"
            }
            className={"w-5 h-5 md:w-8 md:h-8 -mt-px"}
            style={{
              color: "white",
            }}
          />
        </button>*/}
        {!!pdfLinks.length && (
          <button
            className="tooltip-button underline md:no-underline md:bg-gray-100 text-gray-800 p-2 interactive"
            onClick={() => {
              trackEvent({
                category: 'diaraum-darsteller',
                action: 'Darsteller als PDF',
              });
              document.getElementById('singleViewTop').scrollIntoView();
              setZipModal(!zipModal);
              // //createDownloads(pdfLinks, id, sedcard.Name );
              // if (pdfLinks.length > 1) {
              //   return showModal("ShowPdfs");
              // }
              // window.open(pdfLinks[0]);
            }}
          >
            PDF{pdfLinks.length > 1 ? 's' : ''}{' '}
            <span className="hidden xl:inline">herunterladen</span>
            <div className="tooltip absolute bg-gray-100 text-gray-800 p-4 text-left normal-case">
              SedCard, Videos und Polas von {sedcard?.Name} herunterladen oder
              weiterleiten
            </div>
          </button>
        )}
      </header>

      {sedcard.Sedcards && sedcard.Sedcards.length > 0 && (
        <>
          <Grid
            key={id + Math.random()}
            gridCols={3}
            className="m-3 lg:m-4 border"
            noBranding
            items={R.insert(
              -1,

              { type: 'personInfo', sedcard },

              R.map(({ i }) => {
                return { type: 'image', src: i };
              }, R.flatten(R.map((i) => ({ i }), R.concat(sedcard.Sedcards[0].Oben, sedcard.Sedcards[0].Unten))))
            )}
          />

          {sedcard.Videos && sedcard.Videos.length > 0 && (
            <div className="my-4">
              <h2 className="mx-3 lg:mx-4">Videos</h2>
              <Grid
                key={id + Math.random() + 1}
                gridCols={3}
                className="m-3 lg:m-4"
                noBranding
                items={R.map(({ i }) => {
                  return { type: 'video', src: i };
                }, R.flatten(R.map((i) => ({ i }), sedcard.Videos)))}
              />
            </div>
          )}
          {sedcard.Sedcards && sedcard.Sedcards.length > 0 && (
            <>
              <div className="mx-3 lg:mx-4 my-4 ">
                <BookersInfo text={sedcard.Bemerkung} headline="Bemerkungen" />
                <hr className="my-4" />
                {answerImages.length > 0 && (
                  <>
                    <BookersInfo text={answerImages} headline="Polas" />
                    <hr className="my-4" />
                  </>
                )}
                <Roles
                  role={sedcard.Rolle}
                  casterRoles={sedcard.CasterRollen}
                  casterNote={sedcard.CasterBemerkung}
                  id={id}
                  key={id}
                />
                <hr className="my-4" />
              </div>

              <Grid
                key={id + Math.random() + 5}
                gridCols={3}
                className="m-3 lg:m-4 down"
                noBranding
                items={R.map(
                  ({ i, Download }) => ({ type: 'image', src: i, Download }),
                  R.flatten(
                    R.map(
                      (s) =>
                        R.map(
                          (i) => ({ i, Download: s.Download }),
                          R.concat(s.Unten, s.Oben)
                        ),
                      R.remove(0, 1, sedcard.Sedcards)
                    )
                  )
                )}
              />
              <div className="h-10 mb-5" />
            </>
          )}
        </>
      )}
      {/* !!similarFaces.length && (
        <div
          className="text-brand-500 text-xl font-bold mt-12 mb-4 p-2 lg:p-4"
          style={{ fontFamily: "'DM Sans', sans-serif" }}
        >
          <div className="h-2 bg-brand-500 mb-4" style={{ width: "30%" }} />
          weitere passende modelle
        </div>
      )
      <Grid
        gridCols={4}
        className="mx-3 lg:mx-4 mb-16"
        noBranding
        showModal={showModal}
        items={similarFaces
          .map((s) => (sedcards[s.IDNummer] ? sedcards[s.IDNummer] : s))
          .map((s) => ({ type: "person", ...s }))}
        onClick={(id, secret) => {
          pushStack({ type: "ViewSingle", options: { id, secret } });
        }}
        setOptionActorStatus={setOptionActorStatus}
      /> */}
    </Screen>
  );
};
