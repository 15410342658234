import React from "react";
import classNames from "classnames";
import { SwitchTransition, CSSTransition } from "react-transition-group";
import useResizeObserver from "use-resize-observer";

import ProgressBar from "../progress-bar";
import BackButton from "../back-button";
import dissoc from 'ramda/src/dissoc';

import { usePrevious } from "../../utils";

interface PropTypes {
  stack: any;
  popStack: () => void;
  pushStack: (screen: any) => void;
  listView?: React.ReactNode;
  singleView?: React.ReactNode;
  percentage: number;
  isBackButtonVisible: boolean;
  isCloseIconVisible: boolean;
  onDrop?: any;
  wrapperStyle?: any;
  StageItemID?: any;
}

const SplitScreen: React.FC<PropTypes> = ({
  stack,
  popStack,
  listView,
  singleView = <div/>,
  percentage,
  isBackButtonVisible,
  isCloseIconVisible,
  StageItemID,
}) => {
  const screen = stack[stack.length - 1];
  const [ref, , height] = useResizeObserver();
  const prevStack = usePrevious(stack);

  return (
    <div
      className={classNames(
        "screenManager h-screen print:h-auto w-full md:flex md:justify-center",
        height > window.innerHeight ? "md:items-start" : "md:items-center"
      )}
    >
      <div
        ref={ref}
        className={classNames(
          "w-full md:relative md:bg-white md:overflow-hidden print:overflow-auto desktopBoxShadow transition-height"
        )}
      >
        <ProgressBar percentage={percentage || 0} />

        <div className="flex justify-center flex-row items-start w-full overflow-hidden  print:overflow-auto">
          <div className="w-full h-screen print:h-auto md:w-1/2 print:w-full overflow-hidden overflow-y-scroll print:overflow-auto listView">
            {listView}
          </div>
          <SwitchTransition>
            <CSSTransition
              timeout={600}
              classNames={classNames(
                stack.length >= (prevStack.length || 0)
                  ? "my-animated-screen"
                  : "fadeover-backwards"
              )}
              in={true}
              key={JSON.stringify(dissoc('options', screen))}
            >
              <div
                className={classNames(
                  "transition-opacity w-full h-screen md:w-1/2 absolute md:relative z-50 md:z-auto bg-white overflow-hidden overflow-y-scroll singleView print:hidden",
                  { "hidden md:block": !StageItemID }
                )}
              >
                <BackButton
                  id={screen.type}
                  isCloseIcon={isCloseIconVisible}
                  isVisible={isBackButtonVisible && stack.length > 1}
                  onBack={popStack}
                />

                {singleView}
              </div>
            </CSSTransition>
          </SwitchTransition>
        </div>
      </div>
    </div>
  );
};

export default SplitScreen;
