import React, { useEffect, useState } from "react";
import Screen from "../../../../../components/screen";
import { useModuleState } from "../../../state";
import classNames from "classnames";
import LogoWatermark from "../../../../../components/logo-watermark";
import * as R from "ramda";
import { useMinWidth } from "../../../../../utils";
import SecondaryButton from "../../../../../components/secondary-button";
import Grid from "../../../../../components/grid/Grid";
import Icon from "../../../../../components/icon-2";
import TableView from "../../../../../components/tableView/TableView";
import { useMatomo } from "@datapunt/matomo-tracker-react";
import ZipModal from "./zip-modal";
import MailModal from "./mail-modal";

export default () => {
  const {
    data: { slideRoom, sedcards },
    pushStack,
    showModal,
    setOptionActorStatus,
  } = useModuleState();
  const desktop = useMinWidth(650);
  const { trackPageView, trackEvent } = useMatomo();
  const availableRoles = R.uniq(
    (Object.values(sedcards) || []).map((s: any) => s.Rolle).filter((x) => x)
  );

  const [selectedRole, setSelectedRole] = useState<any>();
  const [tab, setTab] = useState(0);
  const [zipModal, setZipModal] = useState<boolean>();
  const [mailModal, setMailModal] = useState<boolean>();
  const [currentFavoriteActors, setCurrentFavoriteActors] = useState<
    Array<Record<string, any>>
  >([{}]);
  const favoriteActors = () =>
    Object.values(sedcards).filter(
      (item) => item["Favoriten-Status"] || item["AngefragtStatus"]
    );

  useEffect(() => {
    setCurrentFavoriteActors(favoriteActors());
  }, [sedcards]);

  if (!slideRoom) {
    return null;
  }
  const visibleActors = Object.values(sedcards)
    .filter(
      (s: any) =>
        (s.OptionPosID &&
          // eslint-disable-next-line no-mixed-operators
          (!selectedRole || s.Rolle === selectedRole) &&
          (!tab ||
            // eslint-disable-next-line no-mixed-operators
            (tab === 1 && s.AngefragtStatus === true))) ||
        (tab === 2 &&
          s["Favoriten-Status"] &&
          (!selectedRole || s.Rolle === selectedRole)) ||
        (tab === 3 && s.Status === "1. Option") ||
        (tab === 3 && s.Status === "1. Option(Siehe Bemerkung!)") ||
        (tab === 4 && s.BuchenStatus === "Ja")
    )
    .sort(
      (a: any, b: any) =>
        (b.Status ? 1 : 0) +
        (b["BuchenStatus"] ? 1 : 0) +
        (b["Favoriten-Status"] ? 1 : 0) -
        (a.Status ? 1 : 0) -
        (a["BuchenStatus"] ? 1 : 0) -
        (a["Favoriten-Status"] ? 1 : 0)
    );
  const { OptionID, Titel /*BookerMail, BookerTel*/ } = slideRoom;

  const displayTooltipText = () => {
    switch (tab) {
      case 0: {
        return "Download des gesamten Castings im PDF Format oder mit den Videos als ZIP Format per Email";
      }
      case 1: {
        return "Download Angefragt/Optionen im PDF Format oder mit den Videos als ZIP Format per Email";
      }
      case 2: {
        return "Download der Auswahl im PDF Format oder mit den Videos als ZIP Format per Email";
      }
      default: {
        return "Liste der Darsteller";
      }
    }
  };

  useEffect(() => {
    trackPageView({ documentTitle: "Diaraum Übersicht" });
  }, [trackPageView]);

  return (
    <Screen>
      <ZipModal
        slideRoom={slideRoom}
        visibleActors={visibleActors}
        showModal={zipModal}
        screen={"screen"}
      ></ZipModal>
      <MailModal
        slideRoom={slideRoom}
        showModal={mailModal}
        screen={"screen"}
      ></MailModal>
      <header className="listHeader py-2 px-4 md:px-8">
        <div className="w-full mt-2 mb-2 flex flex-wrap align-middle">
          <div className="flex flex-row flex-no-wrap gap-2">
            {[
              "Zeige Alle >",
              "Zeige Angefragt / Optionen >",
              "Zeige Auswahl >" /*, "Sofort buchbar", "Gebucht"*/,
            ].map((item, index) => (
              <button
                key={index}
                onClick={() => {
                  setTab(index);
                  trackEvent({
                    category: "diaraum-menu",
                    action: `Menü ${item}`,
                  });
                }}
                className={classNames(
                  "text-base focus:outline-none interactive text-black border px-4 py-2 uppercase noselect",
                  "w-50 md:w-auto mb-2",
                  "block xl:inline text-left",
                  index === 0 ? "" : "xl:text-center",
                  index === tab
                    ? "font-bold text-white bg-black border-none"
                    : ""
                )}
                style={{ fontFamily: "'DM Sans', sans-serif" }}
              >
                {item}
              </button>
            ))}
          </div>

          <div className="flex-grow flex align-middle justify-start">
            <button
              className={classNames(
                "tooltip-button relative text-sm text-black border px-2 py uppercase flex items-center interactive text-black mr-2"
              )}
              onClick={() => {
                trackEvent({
                  category: "diaraum-menu",
                  action: "Auswahl als PDF",
                });
                setMailModal(!mailModal);
              }}
            >
              Zeige Casting weiterleiten/teilen &gt;
              <div className="tooltip absolute bg-gray-100 text-gray-800 p-4 text-left normal-case">
                Schicken Sie ihre Auswahl als Link per Email zu
              </div>
            </button>
            <button
              // disabled={tab === 0 || visibleActors.length === 0}
              className={classNames(
                "tooltip-button relative text-black text-sm border px-2 py uppercase flex items-center interactive text-black mr-2"
              )}
              onClick={() => {
                trackEvent({
                  category: "diaraum-menu",
                  action: "Auswahl als PDF",
                });
                // if (visibleActors.length > 10) {
                //   return showModal("ShowPdfs", { visibleActors });
                // }
                // createMultiDownloads(visibleActors, OptionID, Titel, 0, 10);
                setZipModal(!zipModal);
              }}
            >
              Lade Auswahl per ZIP &gt;
              <div className="tooltip absolute bg-gray-100 text-gray-800 p-4 text-left normal-case">
                {displayTooltipText()}
              </div>
            </button>
            <div className="relative px-2 py-1 border text-sm uppercase text-black flex items-center interactive">
              {selectedRole || "Alle Rollen"}
              <Icon name="down" className="w-4 h-4 ml-2" />
              <select
                className="absolute top-0 h-full w-full opacity-0"
                onChange={(e) => {
                  trackEvent({
                    category: "diaraum-menu",
                    action: `Rollenauswahl ${e.target.value}`,
                  });
                  setSelectedRole(
                    e.target.value === "Alle Rollen"
                      ? undefined
                      : e.target.value
                  );
                }}
              >
                {[undefined, ...availableRoles].map((option, i) => (
                  <option key={i} value={option}>
                    {option || "Alle Rollen"}
                  </option>
                ))}
              </select>
            </div>
          </div>
        </div>
      </header>
      {visibleActors.length ? (
        tab === 1 ? (
          <TableView
            items={visibleActors}
            optionID={OptionID}
            title={Titel}
            onClick={(id, secret) => {
              pushStack({ type: "ViewSingle", options: { id, secret } });
              trackEvent({
                category: "diaraum-darsteller",
                action: `Darstellerauswahl ${id}`,
              });
            }}
          />
        ) : (
          <Grid
            gridCols={4}
            items={
              tab === 0
                ? visibleActors
                    .map((s: any) => ({ type: "person", ...s }))
                    .filter((item) => {
                      if (!selectedRole) return item;
                      if (item.Rolle === selectedRole) return item;
                    })
                : currentFavoriteActors
            }
            onClick={(id, secret) => {
              pushStack({ type: "ViewSingle", options: { id, secret } });
              trackEvent({
                category: "diaraum-darsteller",
                action: `Darstellerauswahl ${id}`,
              });
            }}
            showModal={showModal}
            setOptionActorStatus={setOptionActorStatus}
            className="mb-16"
            noBranding
          />
        )
      ) : (
        <div className="flex justify-center mt-8">Keine Ergebnisse</div>
      )}
      <footer className="listFooter">
        <div className="relative" style={{ width: 120 }}>
          <LogoWatermark relative />
        </div>
        <div
          className="text-brand-500 text-xl font-bold"
          style={{ fontFamily: "'DM Sans', sans-serif" }}
        >
          Job #{OptionID} –{" "}
          {Titel.substr(0, 30) + (Titel.length > 30 ? "..." : "")}
        </div>
        <div className="flex justify-end">
          <SecondaryButton
            textStyle={{ color: "white", textAlign: "center" }}
            style={{
              backgroundColor: "#C1002B",
              borderColor: "#C1002B",
              width: desktop ? "12.7rem" : "8rem",
            }}
            onClick={() => {
              showModal("ShowContactInfo");
              trackEvent({ category: "diaraum-footer", action: `KontaktInfo` });
            }}
            icon="phone"
          >
            Kontakt
          </SecondaryButton>
        </div>
      </footer>
    </Screen>
  );
};
