import React from 'react';

const BookersInfo = ({
  headline,
  text,
}: {
  headline: string;
  text: string | any;
}) => {
  const renderContent = () => {
    if (typeof text === 'object') {
      return (
        <div className="flex flex-wrap flex-row">
          {text.map((item: any) => {
            if (
              item[0].endsWith('jpeg') ||
              item[0].endsWith('jpg') ||
              item[0].endsWith('JPEG') ||
              item[0].endsWith('JPG')
            ) {
              return (
                <img
                  key={item.toString()}
                  src={item}
                  style={{ width: 150, height: 'auto', maxHeight: 200 }}
                  loading="lazy"
                  className="mr-4 mb-4 border-brand-300 border"
                  alt="something meaningful"
                />
              );
            } else {
              return (
                <a
                  className="mr-4 mb-4 text-red-600"
                  href={item}
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  Download
                </a>
              );
            }
          })}
        </div>
      );
    }
    return text || 'keine';
  };

  return (
    <div className="bookersInfo">
      <h2 className="text-xl">{headline}</h2>
      {renderContent()}
    </div>
  );
};

export default BookersInfo;
