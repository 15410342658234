import React from "react";
import ShowConfirmBookingModal from "./show-confirm-booking";
import ShowContactInfo from "./show-contact-info";
import ShowPdfs from "./show-pdfs";

export default {
  ShowConfirmBooking: { node: <ShowConfirmBookingModal /> },
  ShowContactInfo: { node: <ShowContactInfo /> },
  ShowPdfs: { node: <ShowPdfs /> },
};
