import React from "react";
import classNames from "classnames";
import { useDimensions } from "../../utils";

import PersonInfo from "./person-info";
import PersonCard from "./person-card";
import SedcardImage from "./SedcardImage";

import { addInfoToItems, getIsLast, getNumberOfColumns } from "./helper";
import SedcardVideo from "./SedcardVideo";

interface PropTypes {
  items: any;
  onClick?: Function;
  setOptionActorStatus?: Function;
  noBranding?: boolean;
  showModal?: Function;
  gridCols?: number;
  className?: string;
}

const Module: React.FC<PropTypes> = ({
  items,
  noBranding,
  onClick,
  setOptionActorStatus,
  showModal,
  gridCols,
  className,
}) => {
  const [windowWidth] = useDimensions();
  const columns = gridCols ? gridCols : getNumberOfColumns(windowWidth);
  return (
    <div
      className={classNames(
        "gridView",
        gridCols &&
          "grid gap-4 md:auto-rows-min mx-3 md:mx-8 sm-grid-cols-1 print:flex print:flex-wrap",
        gridCols === 2 && " lg-grid-cols-1 xxl-grid-cols-2 ",
        gridCols === 3 && " lg-grid-cols-2 xxl-grid-cols-3 ",
        gridCols === 4 && " lg-grid-cols-2 xxl-grid-cols-3 max-grid-cols-4",
        gridCols === 6 && " lg-grid-cols-3 xxl-grid-cols-6 ",
        gridCols === 8 && " lg-grid-cols-4 xxl-grid-cols-8 ",
        !gridCols && "flex flex-row flex-wrap",
        className
      )}
    >
      {addInfoToItems(items, columns, noBranding).map(
        (item: any, index: any) => {
          const isNotLast = !getIsLast(columns, index);
          if (item === null) {
            return (
              <div
                key={String(index)}
                className={classNames(
                  "mb-8 listViewItem",
                  !gridCols && "listViewItemDefault"
                )}
                style={{
                  marginRight: isNotLast ? "1rem" : undefined,
                  height: "inherit",
                  width: columns === 1 ? "100%" : undefined,
                }}
              />
            );
          }
          if (
            typeof item === "object" &&
            !["person", "image"].includes(item.type)
          ) {
            if (item.type === "logo") {
              return (
                <div
                  key={String(index)}
                  className={classNames(
                    "mb-8 listViewItem",
                    !gridCols && "listViewItemDefault"
                  )}
                  style={{
                    height: "inherit",
                    width: columns === 1 ? "100%" : undefined,
                    marginRight: isNotLast ? "1rem" : undefined,
                  }}
                >
                  <img
                    src="https://procast-staticassets.s3.eu-central-1.amazonaws.com/images/procast-logo-small.png"
                    style={{ width: "8.6rem" }}
                    alt="Procast logo"
                  />
                </div>
              );
            }
            if (
              ["hamburg", "berlin", "munich", "general"].includes(item.type)
            ) {
              return (
                <div
                  key={String(index)}
                  className={classNames(
                    "mb-8 listViewItem relative",
                    !gridCols && "listViewItemDefault"
                  )}
                  style={{
                    marginRight: isNotLast ? "1rem" : undefined,
                    marginBottom:
                      columns === 1 && index === 2 ? "1rem" : undefined,
                    height: "inherit",
                    width: columns === 1 ? "100%" : undefined,
                  }}
                ></div>
              );
            }
          }
          if (typeof item === "object" && item.type === "image") {
            return <SedcardImage key={index} item={item} gridCols={gridCols} />;
          }
          if (typeof item === "object" && item.type === "video") {
            return <SedcardVideo key={index} item={item} gridCols={gridCols} />;
          }

          if (typeof item === "object" && item.type === "personInfo") {
            return (
              <PersonInfo
                gridCols={gridCols}
                key={index}
                item={item}
                isNotLast={isNotLast}
                index={index}
                columns={columns}
              />
            );
          }
          return (
            <PersonCard
              gridCols={gridCols}
              showModal={showModal}
              key={index}
              item={item}
              index={index}
              columns={columns}
              onClick={onClick}
              setOptionActorStatus={setOptionActorStatus}
            />
          );
        }
      )}
    </div>
  );
};

export default Module;
