import React, { useRef, useState, useEffect } from "react";
import ApiButton from "./zip-modal-button";

export default function ZipModal({
  slideRoom,
  visibleActors,
  showModal,
  screen,
}: {
  slideRoom: Record<string, any>;
  visibleActors: any;
  showModal: boolean;
  screen: "screen" | "full";
}) {
  // options, title
  const { OptionID, Titel } = slideRoom;

  // zip modal
  const [zipModal, setZipModal] = useState<boolean>(true);
  const emailRef = useRef(null);
  const [emailList, setEmailList] = useState<Array<String>>([]);
  const [pdfChecked, setPdfChecked] = useState(false);
  const [videoChecked, setVideoChecked] = useState(false);
  const [polasChecked, setPolasChecked] = useState(false);

  const addEmails = (e: React.KeyboardEvent<HTMLInputElement>) => {
    if (e.key === " " || (e.key === "Enter" && emailRef.current.value !== "")) {
      setEmailList([...emailList, emailRef.current.value]);
      emailRef.current.value = " ";
    }
  };

  useEffect(() => {
    setZipModal(!zipModal);
  }, [showModal]);

  return (
    <>
      {zipModal && (
        <div
          className={`flex ${
            screen === "screen" ? "w-screen" : "w-full"
          } h-screen border justify-center items-center absolute z-10 bg-gray-800 bg-opacity-50`}
        >
          <div className="relative w-1/2 h-auto mx-auto z-20 bg-white rounded-sm shadow-md">
            <div className="absolute top-0 right-0">
              <button
                className="mr-2 mt-2 text-xl font-bold p-2"
                onClick={() => setZipModal(false)}
              >
                X
              </button>
            </div>
            <div className="text-center my-10">
              <h1 className="text-2xl">Download Bereich</h1>
              <hr className="w-16 mx-auto mt-4"></hr>
              <p className="mt-4 text-base font-light px-20">
                Erhalten Sie die Liste der Darsteller in PDF Format oder mit den
                Videos als Zip Format per Email.
              </p>
              <div className="mt-4 w-1/2 mx-auto">
                <p className="text-center">Emails:</p>
                <div className="flex flex-row flex-wrap gap-1 my-2 justify-center">
                  {emailList.map((e) => {
                    return (
                      <span
                        className="text-xxs border text-gray-400 border-gray-200 p-1 rounded-sm"
                        key={Math.random()}
                      >
                        {e}
                      </span>
                    );
                  })}
                </div>
                <input
                  type="email"
                  defaultValue={emailRef?.current?.value}
                  className="border border-gray-200 rounded-sm text-center text-sm py-2 px-4"
                  ref={emailRef}
                  placeholder="max@mustermann.de"
                  onChange={(e: React.ChangeEvent<HTMLInputElement>) =>
                    (emailRef.current.value = e.target?.value)
                  }
                  onKeyDown={(e: React.KeyboardEvent<HTMLInputElement>) =>
                    addEmails(e)
                  }
                ></input>
                <p className="text-center text-xs font-light mt-1">
                  Hinweis: Eingabe (ihre Email) mit <br></br>
                  <span className="font-bold">SPACE / ENTER </span>
                  bestätigen
                </p>
              </div>
              <div className="flex flex-col justify-center items-center w-full mt-4">
                <div>
                  <div className="flex flex-row items-center">
                    <input
                      type="checkbox"
                      className="mr-1"
                      defaultChecked={pdfChecked}
                      onChange={() => setPdfChecked(!pdfChecked)}
                    ></input>
                    <p className="text-sm font-light">SetCard (PDF)</p>
                  </div>
                  <div className="flex flex-row items-center">
                    <input
                      type="checkbox"
                      className="mr-1"
                      defaultChecked={videoChecked}
                      onChange={() => setVideoChecked(!videoChecked)}
                    ></input>
                    <p className="text-sm font-light">
                      Videos (Falls vorhanden)
                    </p>
                  </div>
                  <div className="flex flex-row items-center">
                    <input
                      type="checkbox"
                      className="mr-1"
                      defaultChecked={polasChecked}
                      onChange={() => setPolasChecked(!polasChecked)}
                    ></input>
                    <p className="text-sm font-light">
                      Polas (Falls vorhanden)
                    </p>
                  </div>
                </div>
                <ApiButton
                  OptionID={OptionID}
                  Titel={Titel}
                  pdfChecked={pdfChecked}
                  slideRoom={slideRoom}
                  videoChecked={videoChecked}
                  visibleActors={visibleActors}
                  emailList={emailList}
                  emailState={setEmailList}
                  polasChecked={polasChecked}
                ></ApiButton>
              </div>
            </div>
          </div>
        </div>
      )}
    </>
  );
}
