import flatten from "ramda/src/flatten";

export const getNumberOfColumns = (w: any) =>
  w >= 1158 ? 4 : w >= 930 ? 3 : w >= 650 ? 2 : w >= 620 ? 3 : w >= 430 ? 2 : 1;

export const getIsLast = (c: number, i: number) => !((i + 1) % c);

export const addInfoToItems = (
  items: any[],
  columns: number,
  noBranding: boolean
) => {
  let contentItems;
  if (noBranding) {
    contentItems = items;
  } else {
    // check items length
    const result: any = [];
    items.forEach((item: any, index: number) => {
      result.push(item);
      if (columns === 1 && index === 0 && items.length > 1) {
        result.push([{ type: "logo" }, { type: "general" }]);
      } else if (columns === 2 && index === 1 && items.length > 2) {
        result.push([{ type: "logo" }, { type: "general" }]);
      } else if (columns === 3 && index === 2 && items.length > 3) {
        result.push([{ type: "logo" }, { type: "general" }, null]);
      } else if (columns === 4 && index === 3 && items.length > 4) {
        result.push([
          { type: "logo" },
          { type: "hamburg" },
          { type: "berlin" },
          { type: "munich" },
        ]);
      }
    });
    contentItems = flatten(result);
  }
  return contentItems;
};
