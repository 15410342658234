import { useCallback } from "react";
import * as R from "ramda";
import { post } from "../../../utils/request";

function getSimilarFaceSedcard(data, actorId) {
  const allSimilarFaces = R.flatten(Object.values(data.similarFacesById));
  const similarFace = allSimilarFaces.find((f) => f.IDNummer === actorId);
  return similarFace;
}

function getActionType(d, updateType, actorId) {
  if (updateType === "BOOK") {
    return "Buchen";
  }
  if (updateType === "UNREQUEST") {
    return "AnfrageAufgehoben";
  }

  return !d.sedcards[actorId] || !d.sedcards[actorId].OptionPosID
    ? updateType === "REQUEST"
      ? "AnfrageNeu"
      : "FavoritNeu"
    : updateType === "REQUEST"
    ? "Anfrage"
    : "Favorit";
}

function getOptionPosId(d, actorId) {
  return d.sedcards[actorId] && d.sedcards[actorId].OptionPosID
    ? d.sedcards[actorId].OptionPosID
    : undefined;
}

function getRequestObject(
  updateType,
  actionType,
  optionJWT,
  actorId,
  optionPosId
): any {
  // console.log('getRequestObject',updateType, actionType, )
  if (["FAVORITE", "UNFAVORITE"].includes(updateType)) {
    return {
      type: actionType,
      optionJWT,
      actorId,
      isFavorited: updateType === "FAVORITE",
      OptionPosID: optionPosId,
    };
  } else if (["REQUEST", "UNREQUEST"].includes(updateType)) {
    //  console.log('getRequestObject', )
    return {
      type: actionType,
      optionJWT,
      actorId,
      OptionPosID: optionPosId,
    };
  } else if (updateType === "BOOK") {
    return {
      type: actionType,
      optionJWT,
      actorId,
      OptionPosID: optionPosId,
    };
  } else if (updateType === "ROLES") {
    return {
      type: actionType,
      optionJWT,
      actorId,
      OptionPosID: optionPosId,
    };
  }
}

function getUpdatedSedcard(updateType, sedcard) {
  const updatedSedcard = { ...sedcard };

  if (["FAVORITE", "UNFAVORITE"].includes(updateType)) {
    updatedSedcard["Favoriten-Status"] = updatedSedcard === "FAVORITE";
  } else if (updateType === "REQUEST") {
    updatedSedcard["Status"] = "Kundeninteresse";
  } else if (updateType === "UNREQUEST") {
    updatedSedcard["Status"] = "";
  }
  // console.log("getUpdatedSedcard",updateType, updatedSedcard)
  return updatedSedcard;
}

export default function useFavorites(flowState) {
  const {
    setData,
    data: { optionJWT },
  } = flowState;

  const setOptionActorStatus = useCallback(
    (updateType, actorId, cb?, selectedRole?, casterRoles?, casterNote?) =>
      setData((d) => {
        if (
          updateType === "ROLES" ||
          updateType === "FAVORITE" ||
          updateType === "UNFAVORITE" ||
          updateType === "REQUEST" ||
          updateType === "UNREQUEST" ||
          updateType === "BOOK"
        ) {
          // add sedcard if does not exist yet
          // else only update Favoriten-Status
          let sedcard =
            d.sedcards[actorId] || getSimilarFaceSedcard(d, actorId);
          const actionType = getActionType(d, updateType, actorId);
          const OptionPosID = getOptionPosId(d, actorId);
          const requestObject = getRequestObject(
            updateType,
            actionType,
            optionJWT,
            actorId,
            OptionPosID
          );
          const updatedSedcard = getUpdatedSedcard(updateType, sedcard);

          if (selectedRole) {
            requestObject.Rolle = selectedRole;
          }

          if (casterRoles) {
            requestObject.CasterRollen = casterRoles;
          }
          if (casterNote) {
            requestObject.CasterBemerkung = casterNote;
          }

          // console.log("set user update", requestObject, updatedSedcard );

          post("set-optionactor-status", requestObject).then((res) => {
            setData((x) => ({
              ...x,
              sedcards: R.assoc(actorId, res, x.sedcards),
            }));
            cb && cb();
          });

          return {
            ...d,
            sedcards: R.assoc(actorId, updatedSedcard, d.sedcards),
          };
        }
        return d;
      }),
    [optionJWT, setData]
  );
  return { setOptionActorStatus };
}
