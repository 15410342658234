import React from "react";
import SplitScreen from "../../components/screen-manager/SplitScreen";
import screens from "./components/screens";
import Modal from "../../components/modal";
import modals from "./components/modals";
import { useModuleState } from "./state";

import DefaultPage from "../default";
export default () => {
  const {
    state: { stack },
    data,
    popStack,
    pushStack,
    setModalVisibility,
  } = useModuleState();


  const screen = stack[stack.length - 1];
  const screenOptions = {
    ...(screens[screen.type].options || {}),
    ...screen.options,
  };

  if (!data.slideRoom) {
      return (<DefaultPage text={''} />);
  }

  return (
    <div className="relative overflow-hidden print:overflow-auto">
      <SplitScreen
        stack={stack}
        popStack={popStack}
        pushStack={pushStack}
        percentage={screenOptions.percentage}
        isBackButtonVisible={!screenOptions.hideBackButton}
        isCloseIconVisible={screenOptions.showCloseIcon}
        listView={screens["ViewList"].node}
        singleView={screens["ViewSingle"].node}
        StageItemID={screenOptions.id}
      />
      <Modal
        visible={!!screenOptions.modalVisible}
        setVisible={setModalVisibility}
        style={
          modals[screenOptions.modalName]
            ? modals[screenOptions.modalName].style
            : undefined
        }
        preventExit={
          modals[screenOptions.modalName] &&
          modals[screenOptions.modalName].preventExit
        }
      >
        {screenOptions.modalName ? modals[screenOptions.modalName].node : null}
      </Modal>

    </div>
  );
};
