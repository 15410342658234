import { useEffect } from 'react';
import { getScreen } from '../../../selectors/portfolio-viewer';
import config from '../../../config';
import * as R from 'ramda';

export default function useSedcards({ state, data, setData }) {

  const params = (new URL(document.location.toString())).searchParams;
  const screenID = params.get("detail") ? params.get("detail") : ""


  const screen = getScreen(state);
  const id = screen.options ? screen.options.id : screenID;
  const secret = screen.options ? screen.options.secret : '';

  useEffect(() => {
    // fetch from slideRoom or if not available from the actor endpoint
    if (!id) {
      return;
    }

    const sedcard = R.path(['sedcards', id], data);
    if (sedcard) {
      return;
    }

    (async () => {
      // fetch from remote
      const res = await fetch(
        config.flows['slide-room'].apiBaseUrl + '/sedcard?id=' + secret
      );
      const json = await res.json();

      setData((d) => ({
        ...d,
        sedcards: R.assoc(id, json, d.sedcards || {}),
      }));
    })();
    // fetch from endpoint
  }, [id, setData, data, secret]);
}
