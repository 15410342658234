import React from "react";
import SecondaryButton from "../../../../components/secondary-button";
import { getButtonParameters } from "../../../../utils";
import { useModuleState } from "../../state";
import classNames from "classnames";

export default function RequestButton({ id }) {
  const {
    setOptionActorStatus,
    showModal,
    data: { sedcards },
  } = useModuleState();
  /* const availableRoles = R.uniq(
    (Object.values(sedcards) || []).map((s: any) => s.Rolle).filter((x) => x)
  ); */

  const sedcard = sedcards[id];
  const { label, type } = getButtonParameters(sedcard);

  return (
    <SecondaryButton
      textStyle={{ color: "white", textAlign: "center" }}
      disabled={type !== "enabled"}
      wrapperClassNames={classNames(
        type === "enabled"
          ? "bg-brand-500 border-brand-500"
          : type === "passive"
          ? "bg-brand-300 border-brand-300"
          : "bg-brand-500 border-brand-500"
      )}
      onClick={() => {
        if (
          sedcard.Status === "1. Option" ||
          sedcard.Status === "1. Option(Siehe Bemerkung!)"
        ) {
          return showModal("ShowConfirmBooking", { id: sedcard.IDNummer });
        }

        setOptionActorStatus("REQUEST", id);
      }}
      icon={!sedcard.Status ? "calendar" : undefined}
    >
      {label}
    </SecondaryButton>
  );
}
