import React, { useState } from 'react';
import axios, { AxiosRequestConfig } from 'axios';
// import { parseFacts } from "../../../../../utils/index";

export default function ZipModalButton({
  visibleActors,
  OptionID,
  Titel,
  slideRoom,
  pdfChecked,
  videoChecked,
  emailList,
  emailState,
  polasChecked,
}) {
  enum EmailResponse {
    success = 'Email erfolgreich gesendet!',
    error = 'Es ist ein Fehler aufgetreten',
  }

  // const convertURLFromString = (url: string) => {
  //   if (!polasChecked) return [];
  //   if (url === "") return [];
  //   return parseFacts(url)
  //     .map((i) => (i.key === "Aktuelles Pola / Selfie" ? i.value : null))
  //     .filter((n) => n);
  // };

  const zipApi = `https://zip-generator${
    window.location.hostname.includes('dev') ? '.dev' : ''
  }.backend.procast.de`;
  const [emailSend, setEmailSend] = useState<boolean>(false);
  const [notificationText, setNotificationText] = useState<string>(
    EmailResponse.success
  );

  const emailSendTrigger = (text) => {
    setNotificationText(text);
    setEmailSend(true);
    setTimeout(() => {
      setEmailSend(false);
    }, 3000);
  };

  const getActorSedcards = (e) => {
    if (pdfChecked) {
      return e.Sedcards.map((section) => {
        return section?.DownloadFile;
      });
    }
    return [];
  };

  const getActorVideos = (e) => {
    if (videoChecked) {
      return e?.VideoFiles;
    }
    return [];
  };

  // const getActorImages = (e) => {
  //   const imageArray = e?.Sedcards.map((e) => {
  //     return e?.ImageFiles;
  //   });
  //   return [].concat(...imageArray);
  // };

  const getPolas = (e) => {
    if (!polasChecked) return [];
    if (!e.Polas) return [];
    return e?.Polas;
  };

  const getActors = () => {
    return visibleActors.map((e: any) => {
      return {
        id: e.IDNummer,
        firstName: e.Name,
        files: [
          ...getPolas(e),
          ...getActorSedcards(e),
          ...getActorVideos(e),
          // ...getActorImages(e),
        ],
      };
    });
  };

  const generateJsonObject = () => {
    return {
      emails: [...emailList],
      casting: {
        id: OptionID,
        name: Titel,
      },
      booker: {
        fullName: slideRoom.Booker,
        telephone: slideRoom.BookerTel,
      },
      actors: getActors(),
    };
  };

  const zipAPICall = () => {
    if (emailList === []) return;
    try {
      let config: AxiosRequestConfig = {
        headers: {
          'Access-Control-Allow-Origin': `${
            window.location.hostname.includes('dev')
              ? 'https://dev.diaraum.procast.de/*'
              : 'https://diaraum.procast.de/*'
          }`,
          'Access-Control-Allow-Methods': 'GET,PUT,POST,DELETE,PATCH,OPTIONS',
          'Content-Type': 'application/json',
          'Access-Control-Allow-Headers':
            'Content-Type, Access-Control-Allow-Headers, Authorization, X-Requested-With',
        },
      };
      axios
        .post(zipApi, JSON.stringify(generateJsonObject()), config)
        .then((res) => {
          emailSendTrigger(EmailResponse.success);
        });
      emailState([]);
    } catch (err) {
      emailSendTrigger(err.message);
    }
  };

  return (
    <>
      <button
        className="border border-red-600 text-red-600 text-md font-light uppercase mt-4 px-8 py-2"
        onClick={() => {
          zipAPICall();
        }}
        disabled={emailList.length === 0 ? true : false}
      >
        Senden
      </button>
      {emailSend ? (
        <p className="text-green text-sm mt-2">{notificationText}</p>
      ) : (
        <p className="opacity-0 text-sm mt-2">Email erfolgreich gesendet!</p>
      )}
    </>
  );
}
