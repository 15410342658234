import React from "react";
import { useCachedSrc } from "../../utils";
import classNames from "classnames";

const SedcardImage = ({ item, gridCols }: { item: any; gridCols: any }) => {
  const src = useCachedSrc(item.src);
  return (
    <div
      className={classNames(
        "relative mb-2 listViewItem",
        !gridCols && "listViewItemDefault"
      )}
    >
      {/* <img src={src} className="w-full" loading="lazy" alt="Darsteller-Bild" /> */}
      <img src={src} className="w-full" loading="lazy" alt="Darsteller-Bild" />
    </div>
  );
};

export default SedcardImage;
