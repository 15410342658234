import React, { useRef, useState, useEffect } from 'react';
import { post } from '../../../../../utils/request';

export default function MailModal({
  slideRoom,
  showModal,
  screen,
}: {
  slideRoom: any;
  showModal: boolean;
  screen: 'screen' | 'full';
}) {
  // options, title
  const { OptionID, Titel } = slideRoom;

  // mail modal
  const [mailModal, setMailModal] = useState<boolean>(true);
  const emailRef = useRef(null);
  const firsNameRef = useRef(null);
  const lastNameRef = useRef(null);

  type ResponseType = {
    type: string;
    message: string;
  };

  const postAPI = 'send-email';
  const [responseMessage, setResponseMessage] = useState<ResponseType>({
    type: 'success',
    message: 'Ihre Nachricht wurde versendet!',
  });
  const [showResponse, setShowResponse] = useState<boolean>(false);

  const triggerResponse = ({ type, message }: ResponseType) => {
    setResponseMessage({ type, message });
    setShowResponse(true);
    setTimeout(() => {
      setShowResponse(false);
    }, 3000);
  };

  const mailAPICall = () => {
    if (emailRef?.current?.value === '') return;
    post(postAPI, {
      OptionID,
      KundeMail: emailRef?.current?.value,
      BookerMail: slideRoom.BookerMail,
      URL: window.location.href,
      Titel,
      Name: firsNameRef?.current?.value + ' ' + lastNameRef?.current?.value,
    })
      .then((res) => {
        console.log(res);
        triggerResponse({
          type: 'success',
          message: 'Ihre Nachricht wurde versendet!',
        });
      })
      .catch((err) => {
        console.log('err:' + err);
        // cors errors will send the email but display an error
        triggerResponse({
          type: 'success',
          message: 'Ihre Nachricht wurde versendet!',
        });
      });
    emailRef.current.value = ' ';
    firsNameRef.current.value = ' ';
    lastNameRef.current.value = ' ';
  };

  useEffect(() => {
    setMailModal(!mailModal);
  }, [showModal]);

  return (
    <>
      {mailModal && (
        <div
          className={`flex ${
            screen === 'screen' ? 'w-screen' : 'w-full'
          } h-screen justify-center items-center absolute z-10 bg-gray-800 bg-opacity-50`}
        >
          <div className="relative w-1/2 h-auto mx-auto z-20 bg-white rounded-sm shadow-md">
            <div className="absolute top-0 right-0">
              <button
                className="mr-2 mt-2 text-xl font-bold p-2"
                onClick={() => setMailModal(false)}
              >
                X
              </button>
            </div>
            <div className="text-center my-10">
              <h1 className="text-2xl">Liste der Darsteller</h1>
              <hr className="w-16 mx-auto mt-4"></hr>
              <p className="mt-4 text-base font-light px-20">
                Schicken Sie die Liste der ausgewählten Darsteller als Link per
                Email zu.
              </p>
              <div className="mt-4 w-1/2 mx-auto">
                <h2 className="font-bold">
                  An die folgende Person zuschicken:
                </h2>
                <p className="text-center mt-2">Email:</p>
                <input
                  type="email"
                  placeholder="max@mustermann.de"
                  className="border border-gray-200 rounded-sm text-center text-sm py-2 px-4"
                  ref={emailRef}
                  onChange={(e) => (emailRef.current.value = e.target.value)}
                ></input>
                <p className="text-center mt-2">Vorname:</p>
                <input
                  type="email"
                  placeholder="Max"
                  className="border border-gray-200 rounded-sm text-center text-sm py-2 px-4"
                  ref={firsNameRef}
                  onChange={(e) => (firsNameRef.current.value = e.target.value)}
                ></input>
                <p className="text-center mt-2">Nachname:</p>
                <input
                  type="email"
                  placeholder="Mustermann"
                  className="border border-gray-200 rounded-sm text-center text-sm py-2 px-4"
                  ref={lastNameRef}
                  onChange={(e) => (lastNameRef.current.value = e.target.value)}
                ></input>
              </div>
              <button
                className="border border-red-600 text-red-600 text-md font-light uppercase mt-4 px-8 py-2"
                onClick={() => {
                  mailAPICall();
                }}
              >
                Senden
              </button>
              {showResponse ? (
                <p
                  className={`mt-1 text-sm font-normal ${
                    responseMessage.type === 'success'
                      ? 'text-green'
                      : 'text-red-600'
                  }`}
                >
                  {responseMessage?.message}
                </p>
              ) : (
                <p className="mt-1 text-sm font-normal invisible ">
                  {responseMessage?.message}
                </p>
              )}
            </div>
          </div>
        </div>
      )}
    </>
  );
}
