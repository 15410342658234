import React from "react";
import classNames from "classnames";

interface PropTypes {
  item: any;
  isNotLast?: any;
  index?: any;
  columns?: any;
  gridCols?: any;
}

export default function PersonInfo({ item, gridCols }: PropTypes) {
  const { sedcard } = item;

  const generatePersonInfoField = (
    label: string,
    value: string
  ): JSX.Element => {
    return (
      <>
        {label && value === "" ? null : (
          <div className="text-right text-sm px-1 py-px">
            <span>{label}:</span>
            <span className="font-bold"> {value}</span>
          </div>
        )}
      </>
    );
  };

  return (
    <div
      className={classNames(
        "personInfo relative listViewItem flex flex-col justify-end ",
        !gridCols && "listViewItemDefault"
      )}
    >
      <div className="font-bold text-right text-xl px-1">
        {"# " + sedcard.IDNummer}
      </div>
      <div className="font-bold lg:text-3xl text-right py-px px-1">
        {sedcard.Name.toLowerCase()}
      </div>
      <div className="font-bold text-sm text-right py-px px-1">
        {sedcard.Schauspieler ? "schauspieler" : ""}
        <br />
      </div>
      <div className="text-right text-sm px-1 py-px">
        <span>{`${sedcard.Raum.split(" ")[0]?.toLowerCase() ?? "ab:"}`}</span>{" "}
        <span className="font-bold">{`${
          sedcard.Raum.split(" ")[1]?.toLowerCase() ?? "Ort"
        }`}</span>
      </div>
      {generatePersonInfoField(sedcard.GeburtsdatumLabel, sedcard.Geburtsdatum)}
      {generatePersonInfoField(
        sedcard.GesamtgroesseLabel,
        sedcard.Gesamtgroesse
      )}
      {generatePersonInfoField(
        sedcard.GesamtkonfektionLabel,
        sedcard.Gesamtkonfektion
      )}
      {generatePersonInfoField(sedcard.GesamtschuheLabel, sedcard.Gesamtschuhe)}
      {sedcard.Gesamtkragen === "" && sedcard.Gesamtmasse === ""
        ? null
        : sedcard.Gesamtkragen === ""
        ? generatePersonInfoField(sedcard.GesamtmasseLabel, sedcard.Gesamtmasse)
        : generatePersonInfoField(
            sedcard.GesamtkragenLabel,
            sedcard.Gesamtkragen
          )}
      {generatePersonInfoField(sedcard.AugenfarbeLabel, sedcard.Augenfarbe)}
      {generatePersonInfoField(sedcard.HaarfarbeLabel, sedcard.Haarfarbe)}
    </div>
  );
}
