import React from "react";
import ViewList from "./view-list";
import ViewSingle from "./view-single";

export default {
  ViewList: {
    node: <ViewList />,
    options: {
      wrapperStyle: {
        width: "100%",
      },
    },
  },
  ViewSingle: {
    node: <ViewSingle />,
    options: {
      wrapperStyle: {
        width: "100%",
      },
    },
  },
};
