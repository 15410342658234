import {useContext, useEffect, useState} from "react";
import * as R from "ramda";
import qs from "qs";
import config from "../../../config";
import {NotificationsContext} from "../../../config/context/provider/NotificationsProvider";
// import * as Promise from "bluebird";
// import { post } from "../../../utils/request";

function generateSedcardsObject(json) {
  const sedcards = {};
  json.Darsteller.forEach((d) => {
    sedcards[String(d.IDNummer)] = d;
  });
  return sedcards;
}

/* function mixinSedcards(sedcards, json) {
  json.Darsteller.forEach((d) => {
    sedcards[String(d.IDNummer)] = d;
  });
  return sedcards;
} */

interface Props {
  initial?: boolean;
  setData: any;
  pushStack: any;
}

export default function useSlideroom({ setData, pushStack, initial }: Props) {
  const [isFailed, setIsFailed] = useState(initial);
  const { setNotifications } = useContext(NotificationsContext);
  useEffect(() => {
    const query = qs.parse(window.location.search.substr(1));
    if (!query.id) {
      return;
    }
    const optionJWT =
      R.last(query.id) === "/" ? R.dropLast(1, query.id) : query.id;
        setNotifications('Lade alle Darsteller');
    fetch(config.flows["slide-room"].apiBaseUrl + "/slide-room?id=" + optionJWT)
      .then(async (res) => {
        const json = await res.json();

        setData((d) => ({
          ...d,
          optionJWT: optionJWT,
          slideRoom: R.dissoc("Darsteller", json),
          sedcards: generateSedcardsObject(json),
        }));
        // if actor is available, take it, go there and remove it again
        if (query.actor) {
          pushStack({ type: "ViewSingle", options: { id: query.actor } });
          delete query.actor;
          const newUrl =
            window.location.protocol +
            "//" +
            window.location.host +
            window.location.pathname +
            "?" +
            qs.stringify(query);
          window.history.replaceState({ path: newUrl }, "", newUrl);
        }
        setNotifications();
        // ToDo: Causes API calls to DEATH
        /*(async () => {
          let timestamp;
          while (true) {
            const res = await post("poll-diaraum", {
              optionJWT: optionJWT,
              timestamp,
            });
            timestamp = res.now;
            if (res.status === "NEW") {
              setData((d) => ({
                ...d,
                slideRoom: R.dissoc("Darsteller", res.update),
                sedcards: mixinSedcards(d.sedcards, res.update),
              }));
            }
            // get new Infos every 5 sconds
            await Promise.delay(5000);
          }
        })(); */
      })
      .catch(() => {
        setIsFailed(true);
        setNotifications('Es ist ein Fehler aufgetreten. Bitte versuchen Sie es später noch einmal.');
        //console.log('Es ist ein Fehler aufgetreten. Bitte versuchen Sie es später noch einmal.');
      });
  }, // ToDo: Causes Rerender calls to DEATH!!! Do NOT add dependencies  !!
      [setData]);

  if (isFailed !== undefined) {
    return isFailed;
  }
}
