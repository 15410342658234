import React, { useState } from "react";
import * as R from "ramda";
import classNames from "classnames";

import { useModuleState } from "../../state";
import Icon from "../../../../components/icon-2";
import QuickFacts from "../../../../components/quick-facts";

import { parseFacts } from "../../../../utils";

export default function ShowConfirmBookingModal() {
  const {
    data: { sedcards },
    state: { stack },
    setOptionActorStatus,
    setModalVisibility,
  } = useModuleState();
  const screen = R.last(stack);
  const id =
    screen.options && screen.options.modalOptions
      ? screen.options.modalOptions.id
      : undefined;
  const sedcard = sedcards ? sedcards[id] : undefined;
  const [selectedRole, setSelectedRole] = useState<any>();
  const availableRoles = R.uniq(
    (Object.values(sedcards) || []).map((s: any) => s.Rolle).filter((x) => x)
  );

  if (!sedcard) {
    return null;
  }
  // console.log(sedcard, parseFacts(sedcard.Antworten));
  // fetch sedcard by id
  return (
    <div>
      <div className="mx-5 pt-4">
        <div className="text-brand-700 font-bold mb-2">
          antwort des darstellers
        </div>
        {!!sedcard.Antworten && (
          <QuickFacts
            forceMobileView
            data={parseFacts(sedcard.Antworten.split("*").join(""))}
          />
        )}
      </div>
      <div className="relative bg-brand-500 px-2 mx-4 py-1 mt-8 h-8 text-white flex items-center interactive">
        <div className="flex-grow">{selectedRole || "rolle wählen"}</div>
        <Icon name="down" className="w-4 h-4 ml-2" />
        <select
          className="absolute top-0 h-full w-full opacity-0"
          onChange={(e) => {
            setSelectedRole(
              e.target.value === "rolle wählen" ? undefined : e.target.value
            );
          }}
        >
          {[undefined, ...availableRoles].map((option, i) => (
            <option key={i} value={option}>
              {option || "rolle wählen"}
            </option>
          ))}
        </select>
      </div>
      <div className="px-4 pt-4">
        <button
          className={classNames(
            "w-full bg-brand-700 text-white h-8 font-bold",
            !selectedRole ? "" : "interactive"
          )}
          onClick={() => {
            if (availableRoles.length !== 0 && !selectedRole) {
              return;
            }
            setOptionActorStatus(
              "BOOK",
              id,
              () => setModalVisibility(false),
              selectedRole
            );
          }}
        >
          buchung bestätigen
        </button>
      </div>
    </div>
  );
}
