import React from 'react';
import classNames from 'classnames';

interface PropTypes {
  state: string;
  setState: any;
  stateKey: string;
  placeholder: string;
  onKeyDown?: any;
  unit?: string[] | string;
  className?: string;
  numberOfLines?: number;
  inputClassName?: string;
  autoFocus?: boolean;
}

const TextInput: React.FC<PropTypes> = ({
  numberOfLines = 1,
  unit,
  state,
  setState,
  placeholder,
  onKeyDown,
  className = '',
  inputClassName = '',
  autoFocus
}) => {
  const value = state || '';

  return (
    <div className={classNames('relative flex items-center', className)}>
      {numberOfLines === 1 ? (
        <input
          autoFocus={autoFocus}
          onKeyDown={onKeyDown}
          className={classNames(
            'focus:outline-none py-3 pl-4 w-full text-brand-500 my-2',
            unit && value !== '' ? 'pr-16' : 'pr-4',
            inputClassName
          )}
          style={{
            WebkitAppearance: 'none',
            fontFamily: "'DM Sans', sans-serif",
            boxShadow: 'rgba(184,184,184, 1) 0px 0px 10px 0px'
          }}
          value={value}
          onChange={setState}
          placeholder={placeholder}
          type="text"
        />
      ) : (
        <textarea
          autoFocus={autoFocus}
          onKeyDown={onKeyDown}
          className={classNames(
            'focus:outline-none resize-none py-3 pl-4 w-full text-brand-500 my-2',
            unit && value !== '' ? 'pr-16' : 'pr-4',
            inputClassName
          )}
          placeholder={placeholder}
          value={value}
          onChange={setState}
          rows={numberOfLines}
          style={{
            WebkitAppearance: 'none',
            fontFamily: "'DM Sans', sans-serif",
            boxShadow: 'rgba(184,184,184, 1) 0px 0px 10px 0px'
          }}
        />
      )}
      {value !== '' && unit && (
        <div
          className="absolute right-0 pr-6 text-brand-500"
          style={{
            fontFamily: "'DM Sans', sans-serif"
          }}
        >
          {typeof unit === 'string' ? unit : value === '1' ? unit[0] : unit[1]}
        </div>
      )}
    </div>
  );
};

export default TextInput;
