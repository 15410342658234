import React from "react";
// import jsPDF from 'jspdf';
// import autoTable from 'jspdf-autotable'

interface PropTypes {
  items: any;
  onClick?: Function;
  optionID?: string | number;
  title?: string;
}

const TableView: React.FC<PropTypes> = ({
  items,
  //optionID,
  onClick,
  // title
}) => {
  /*
    const createPDF = () => {
        const doc = new jsPDF()
        autoTable(doc, {
            html: '#actorList',
            alternateRowStyles: { fillColor: '#e9e9e9'}
        })
        doc.save(`Casting-${optionID}–${title}.pdf`)
    }
    */
  return (
    <>
      <button
        onClick={() => {
          window.print();
        }}
        className="btn relative px-2 py-1 h-8 uppercase flex items-center interactive md:bg-gray-100 text-gray-800 mx-3 md:mx-8 mb-5"
      >
        Liste Drucken
      </button>
      <div style={{ overflowX: "auto" }} className="mx-3 md:mx-8 mb-20 ">
        <table className="tableview table-fixed ">
          <thead>
            <tr>
              <th />
              <th className="px-4 py-1 text-left min-w-150 print:min-w-auto">
                Name
              </th>
              <th className="w-1/6 px-4 py-1 text-left min-w-200 print:min-w-auto">
                Rolle <br />
                [Caster-Rollen]
              </th>
              <th className="px-4 py-1 text-left min-w-150 print:min-w-auto">
                Status
              </th>
              <th className="px-4 py-1 text-left min-w-300 print:min-w-auto">
                Bemerkung
              </th>
            </tr>
          </thead>
          <tbody>
            {items.map((item: any) => {
              const roles = item.CasterRollen.substr(
                1,
                item.CasterRollen.length
              ).replace(",", ", ");

              const availabilities = item.BemerkungAvailability.replaceAll(
                "VIELLEICHT",
                "VIELLEICHT|"
              )
                .replaceAll("NEIN", "NEIN|")
                .replaceAll("|,", "|")
                .split("|");

              return (
                <tr
                  key={item.OptionPosID}
                  onClick={
                    onClick
                      ? () => onClick(item.IDNummer, item.SedcardJWT)
                      : undefined
                  }
                >
                  <td className="p-2">
                    <img
                      src={item.BilderTop5[0].path}
                      loading="lazy"
                      alt={item.Name}
                      className="border border-emerald-500"
                    />
                  </td>
                  <td className="px-4 py-2 text-emerald-600 font-medium">
                    {item.Name}
                  </td>
                  <td className=" px-4 py-2 text-emerald-600 font-medium">
                    {item.Rolle}{" "}
                    {item.CasterRollen.length > 1 ? (
                      <>
                        <br />
                        <span>[{roles}]</span>
                      </>
                    ) : null}
                  </td>
                  <td className="px-4 py-2 text-emerald-600 font-medium">
                    {item.Status}
                  </td>
                  <td className="px-4 py-2 text-emerald-600 font-medium">
                    {item.Bemerkung && <p>{item.Bemerkung}</p>}
                    {availabilities.length > 1 && (
                      <p>
                        Bis auf
                        <br />
                        vorgemerkte Termin-Einschränkungen
                        <br />
                        {availabilities.map((item: any) => (
                          <span key={item}>
                            {item}
                            <br />
                          </span>
                        ))}
                      </p>
                    )}
                  </td>
                </tr>
              );
            })}
          </tbody>
        </table>
      </div>
    </>
  );
};

export default TableView;
