import config from '../../config';
import last from 'ramda/src/last';

export const getInitialStack = data => {
  const unseenCreatives = data.Creatives.filter(c => !c.consideredAt);
  if (unseenCreatives.length) {
    return [{ type: 'ViewSingle', creativeId: unseenCreatives[0].id }];
  }
  const unrequestedFavorites = data.Creatives.some(
    c => c.favorited && !c.meetingRequestedAt
  );
  if (unrequestedFavorites) {
    return [{ type: 'ViewList' }, { type: 'ViewRequestAll' }];
  }
  return [{ type: 'ViewList' }];
};

export const getInitialFlow = data => ({
  ...config.flows['portfolio-viewer'],
  initialStack: data.coupleName
    ? [
        {
          type: 'Welcome'
        }
      ]
    : getInitialStack(data)
});

export const getIsComparisonMode = state =>
  !state.stack.find(s => s.type === 'ViewList');

export const getIsRequestAllMode = state =>
  state.stack.some(s => s.type === 'ViewRequestAll');

export const getScreen = state => last(state.stack);
