import React from "react";
import * as R from "ramda";
import { getScreen } from "../../../../selectors/portfolio-viewer";
import { useModuleState } from "../../state";
import { createMultiDownloads } from "../../../../utils/zip-helper";

export default function ShowPdfs() {
  const {
    state,
    data: { sedcards, slideRoom },
  } = useModuleState();

  const screen = getScreen(state);

  const id = screen.options ? screen.options.id : "";

  const sedcard = sedcards[id];
  let pdfLinks;

  if (id) {
    pdfLinks = sedcard.Sedcards.filter((s) => s.Download).map(
      (s) => s.Download
    );
  } else {
    pdfLinks = state.stack[0].options.modalOptions.visibleActors;
  }

  const chunk = 10;
  const renderButtons = () => {
    let i = 0,
      rows = [];
    for (i; i < pdfLinks.length; i += chunk) {
      const temparray = pdfLinks.slice(i, i + chunk);
      const j = i;
      rows.push(
        <button
          key={i}
          className=" underline md:no-underline md:bg-gray-100 text-gray-800 p-2 interactive mx-1 py-3 w-full"
          onClick={() => {
            createMultiDownloads(
              temparray,
              slideRoom.OptionID,
              slideRoom.Titel,
              j,
              chunk
            );
          }}
        >
          PDF-Gruppe {i + 1} - {i + chunk} herunterladen
        </button>
      );
    }
    return rows;
  };

  // fetch sedcard by id
  return (
    <div className="mx-5 my-4 flex flex-col">
      <div className="font-bold mx-1">PDFs herunterladen</div>
      {id ? (
        pdfLinks.map((l, index) => (
          <div key={index} className="mx-1 py-3 w-full">
            <button
              type="button"
              className="interactive bg-gray-200 text-gray-800 p-2 w-full"
              onClick={() => {
                window.open(l);
              }}
            >
              {R.last(l.split("/")).split("?")[0]}
            </button>
          </div>
        ))
      ) : (
        <div className="flex flex-col">
          <div className="mx-1">
            Die Downloads werden anhand Ihrer Auswahl generiert. <br />
            Die Aufteilung in Downloads zu je <b>{chunk}</b> Darstellern ist
            technisch bedingt durch die Größe der Dateien.
          </div>
          <div
            style={{
              flex: "1 1 auto",
              overflow: "auto",
              height: "50vh",
            }}
          >
            <div>{renderButtons()}</div>
          </div>
        </div>
      )}
    </div>
  );
}
