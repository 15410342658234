import React from "react";
import { useCachedSrc } from "../../utils";
import classNames from "classnames";

const SedcardVideo = ({ item, gridCols }: { item: any; gridCols: any }) => {
  const src = useCachedSrc(item.src.path);
  return (
    <div
      className={classNames(
        "relative mb-2 listViewItem",
        !gridCols && "listViewItemDefault"
      )}
    >
      <video src={src} className="w-full" controls>
        Sorry, aber dein Browser unterstützt das angebotene Video-Format nicht.
        <br />
        Aber du kannst es hier{" "}
        <a href={src} target="_blank" rel="noopener noreferrer">
          downloaden
        </a>{" "}
        und es dir lokal auf deinem bevorzugten Player ansehen.
        <br />
        <br />
        Sorry, your browser doesn't support embedded videos,
        <br />
        but don't worry, you can{" "}
        <a href={src} target="_blank" rel="noopener noreferrer">
          download it
        </a>
        and watch it with your favorite video player!
      </video>
    </div>
  );
};

export default SedcardVideo;
