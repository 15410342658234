import React, {useState} from "react";
import TextInput from "../text-input";
import Button from "../action-button";
import {useModuleState} from "../../modules/slide-room/state";
import * as R from "ramda";

interface PropTypes {
    casterRoles: string;
    role: string;
    casterNote: string;
    id: number;
}

const Roles = ({role, casterRoles, casterNote, id}: PropTypes) => {

    // ToDo:  Make availableRoles global
    const {
        data: {sedcards},
        setOptionActorStatus,
    } = useModuleState();
    const availableRoles = R.uniq(
        (Object.values(sedcards) || []).map((s: any) => s.Rolle).filter((x) => x)
    );

    // eslint-disable-next-line @typescript-eslint/no-array-constructor
    const rolesArray = casterRoles ? casterRoles.split(",") : [];
    const [internalRoles, setRoles] = useState(rolesArray);
    const [internalNote, setNote] = useState(casterNote);

    const handleNote = (e: {
        target: { value: React.SetStateAction<string> };
    }) => {
        setNote(e.target.value);
    };

    const handleRoles = (e) => {
        const roles = [...internalRoles];
        const {selected, value} = e.target;
        if (selected) {
            roles.push(value);
        } else {
            const index = roles.indexOf(value);
            if (index > -1) {
                roles.splice(index, 1);
            }
        }
        setRoles(roles);
    }

    const sendNote = () => {
        setOptionActorStatus("ROLES", id, undefined, undefined, internalRoles.toString(), internalNote);
    }

    return (
        <div className="roles">
            <h2 className="text-xl mb-2">
                Vorgeschlagene Rolle: <span className="font-bold">{role}</span>
            </h2>

            <div className="flex flex-col">
                <div className="flex xl:flex-row flex-wrap">
                    <div className="w-full xl:w-1/2 lg:pr-2">
                        <h3>Weitere Rollen:</h3>

                        {/*availableRoles.map((option, i) => (
                            <Checkbox
                                key={i}
                                description={option}
                                isChecked={internalRoles.includes(option)}
                                setIsChecked={handleRoles}
                                className="my-1 "
                            />
                        ))*/}
                        <div className="my-1 h-40">
                            <select
                                multiple
                                className="w-full h-40 py-3"
                                style={{
                                    appearance: "none",
                                    boxShadow: "rgb(184 184 184) 0px 0px 10px 0px"
                                }}
                            >
                                {availableRoles.map((option, i) => (
                                    <option className="px-3 py-2 " key={i} value={option}
                                            selected={internalRoles.includes(option)} onClick={handleRoles}>
                                        {option}
                                    </option>
                                ))}
                            </select>
                        </div>

                    </div>
                    <div className="w-full xl:w-1/2 lg:pr-2 flex flex-col flex-wrap">
                        <h3 className="flex-shrink-0">Nachricht:</h3>
                        <TextInput
                            className="flex-shrink-0 flex-grow my-1 p-0  h-40"
                            inputClassName="h-full "
                            state={internalNote}
                            setState={handleNote}
                            stateKey="note"
                            placeholder="Nachricht für den Booker"
                            numberOfLines={5}
                        />
                    </div>
                </div>

                <Button className="mt-4 self-end" type="secondary" onClick={sendNote}>Änderungen Speichern</Button>
            </div>
        </div>
    );
};

export default Roles;
